import styled from 'styled-components'

export const statusColors = {
  disputed: ['error', 'main'],
  escalated: ['warning', 'main'],
  donotrespond: ['success', 'main'],
  removedatreviewsite: ['success', 'main'],
  ignored: ['success', 'main'],
  posted: ['success', 'main'],
  indraft: ['secondary', 'main'],
  proposed: ['secondary', 'main'],
  ready: ['secondary', 'dark'],
  undrafted: ['primary', 'dark'],
  closed: ['success', 'dark'], // Closed will display for split second before reviews are removed.
  flag: ['error', 'lightest'], // For the dispute REMINDER flag in WorkspaceSidebar.
  notactive: ['foreground', 'lightest'],
}

export const Banner = styled.div`
  border-radius: 20px;
  text-transform: ${props => (props.textTransform ? props.textTransform : 'uppercase')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '75%')};
  text-align: center;
  background-color: ${props =>
    props.bgColor ? props.theme[props.bgColor[0]][props.bgColor[1]] : props.theme.primary.main};
  color: #fff;
  margin: ${props => (props.margin ? props.margin : '4px auto')};
  padding: ${props => (props.padding ? props.padding : '8px 24px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '15px')};
  font-weight: 600;
  ${props => (props.color ? `color: ${props.color};` : null)}
`

export const HorizonatalBar = styled.div`
  border: 1px solid ${props => props.theme.background.dark};
  margin: 0 auto;
  height: 0;
  width: 80%;
`

export const Section = styled.div`
  padding: 20px 18px;
  text-align: left;

  h3 {
    color: ${props => props.theme.text.primary};
    font-size: 18px;
    margin: 0 0 8px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;

    &:nth-child(2) {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      color: ${props => props.theme.foreground.light};
    }
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 14px;
    color: ${props => props.theme.foreground.main};
    margin-bottom: 4px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    color: ${props => props.theme.foreground.light};

    a:hover {
      cursor: pointer;
    }
  }

  a {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    text-decoration-line: underline;
    color: ${props => props.theme.foreground.light};

    &:hover {
      cursor: pointer;
    }
  }
`

export const Header = styled.h3`
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`

export const NotesContainer = styled.div`
  border: 1px solid ${props => props.theme.background.dark};
  border-radius: 20px;
  padding: 20px 20px 0;
  margin: 20px 20px 0;
`

import Service from './service'
import Globals from './global'
import moment from "moment";
const StorageKeys = {
  ExpireTime: 'expiresAt',
  AuthSessionData: 'sessionState',
}
class ReviewsService extends Service {
  async search(criteria) {
    let retval = {},
      url = Globals.toURL('/reviews/search', { ...criteria })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async exportToExcelRequest(){
    let retval = {},
      url = Globals.toURL('/reviews/exportToExcelRequest',null)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async exportToExcelResult(fileName){
    this.session = JSON.parse(localStorage.getItem(StorageKeys.AuthSessionData))
    if (this.session && this.session.accessToken) {
      // Sneeking this into the global scope to peform
      // some behind the scene magic
      window.AccessToken = this.session.accessToken
    }
    let retval = {},
      url = Globals.toURL(`/reviews/exportToExcelResult/${fileName}`,null)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  exportToExcel(criteria,reviews) {
      return fetch(Globals.toURL('/reviews/exportToExcel', {...criteria}), {
        method: 'POST',
        body: JSON.stringify(reviews),
        headers: {
          'Authorization': `Bearer ${window.AccessToken}`,
          'content-type': 'application/json'
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (criteria.tempFileName){
            return true;
          }
          else{
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            const exportDate = moment(new Date()).format('MMDDYYYY')
            link.setAttribute(
              'download',
              `Radar_Manager_Export_${exportDate}.xlsx`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            return true;
          }
        });
  }
  downloadExcelResult(fileName) {
    return fetch(Globals.toURL(`/reviews/downloadExcelResult/${fileName}`, null), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.AccessToken}`,
        'content-type': 'application/json'
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const exportDate = moment(new Date()).format('MMDDYYYY')
        link.setAttribute(
          'download',
          `Radar_Manager_Export_${exportDate}.xlsx`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        return true;
      });
  }
  async getQueueReviews(page, count, query) {
    let retval = {},
      url = Globals.toURL('/reviews/queue', { page, count, ...query })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getStatuses() {
    let retval = {},
      url = Globals.toURL('/reviews/statuses')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getFolders(criteria) {
    let retval = {},
      url = Globals.toURL('/reviews/folders', { ...criteria })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getReview(reviewId) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async saveReview(reviewId, responseData) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}`)
    try {
      retval = await this.post(url, responseData)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async saveReviewAction(reviewId, responseData) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}/action`)
    try {
      retval = await this.post(url, responseData)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async saveReviews(reviewsData) {
    let retval = {},
      url = Globals.toURL(`/reviews/status`)
    try {
      retval = await this.put(url, reviewsData)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getReviewHistory(reviewId) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}/history`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getMetrics() {
    let retval = {},
      url = Globals.toURL(`/reviews/metrics`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getReviewTimeLine(reviewId) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}/timeline`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const ReviewsServiceFactory = function () {}
ReviewsServiceFactory.prototype.create = () => new ReviewsService()

export default ReviewsServiceFactory

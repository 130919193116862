import React, { useState, useEffect } from 'react'
import { Transition, animated } from 'react-spring/renderprops.cjs.js'
import { useTheme } from '@mui/material'
import ButtonSpinner from '../../common/ButtonSpinner'
import FlexContainer from '../../common/FlexContainer'
import { EscalationDisputationButtonsContainer, DisputationMap, Text } from './EscalateButton.S'

function EscalateButton(props) {
  const { review, updateStarted, onClick } = props
  const [resolutionText, setResolutionText] = useState('')
  const savingButtonStyle = { color: '#FFF', padding: '1px' }
  const spinContent = <div style={savingButtonStyle}>Saving..</div>
  const theme = useTheme()

  const checkDisputationButtons = resolutions => {
    switch (true) {
      case resolutions.includes('ApproveProposedResponse'):
      case resolutions.includes('RejectProposedResponse'):
      case resolutions.includes('MarkResponseProposed'):
      case resolutions.includes('MarkManuallyPosted'):
      case resolutions.includes('Close'):
      case resolutions.includes('DoNotRespond'):
        return true
      default:
        return false
    }
  }

  const ProposedDisputed = ({ acceptResolution, rejectResolution, loading, text }) => (
    <FlexContainer justifyContent='flex-start'>
      <h4>{text}: </h4>
      <ButtonSpinner
        key={DisputationMap[acceptResolution].text}
        disableElevation
        style={{
          color: '#FFF',
          margin: '4px 0px 6px 4px',
          backgroundColor:
            theme.palette[DisputationMap[acceptResolution].color][DisputationMap[acceptResolution].variant],
        }}
        variant='contained'
        spin={loading && resolutionText === acceptResolution}
        spinContent={spinContent}
        onClick={() => handlePostResolution(acceptResolution)}>
        {DisputationMap[acceptResolution].text}
      </ButtonSpinner>
      <ButtonSpinner
        key={DisputationMap[rejectResolution].text}
        disableElevation
        style={{
          color: '#FFF',
          margin: '4px 0px 8px 4px',
          backgroundColor:
            theme.palette[DisputationMap[rejectResolution].color][DisputationMap[rejectResolution].variant],
        }}
        variant='contained'
        spin={loading}
        spinContent={spinContent}
        onClick={() => handlePostResolution(rejectResolution)}>
        {DisputationMap[rejectResolution].text}
      </ButtonSpinner>
    </FlexContainer>
  )

  const handlePostResolution = resolution => {
    if (updateStarted) return
    setResolutionText(resolution)
    onClick(resolution)
  }

  useEffect(() => {
    setResolutionText('')
  }, [review.resolutions])

  return (
    <EscalationDisputationButtonsContainer className='col-md-6'>
      <Transition
        native
        items={review.resolutions}
        from={{ opacity: 0, height: 0, transform: 'scale(1)' }}
        enter={[{ opacity: 1, height: 'auto' }]}
        leave={[{ transform: 'scale(1)', opacity: 0.5 }, { opacity: 0 }, { height: 0 }]}>
        {resolution => props => {
          DisputationMap[resolution] && (props.order = DisputationMap[resolution].order)
          if (resolution === 'MarkDisputeDenied') return null
          else if (resolution === 'MarkDisputeAccepted')
            return (
              <animated.span style={props} className='transitiongroup-item'>
                <ProposedDisputed
                  acceptResolution='MarkDisputeAccepted'
                  rejectResolution='MarkDisputeDenied'
                  text='DISPUTED'
                  loading={updateStarted}
                />
              </animated.span>
            )
          else
            return (
              <animated.span style={props}>
                {DisputationMap[resolution] && (
                  <ButtonSpinner
                    key={DisputationMap[resolution].text}
                    disableElevation
                    style={{
                      color: '#FFF',
                      margin: '0 0 8px 0',
                      backgroundColor:
                        theme.palette[DisputationMap[resolution].color][DisputationMap[resolution].variant],
                    }}
                    variant='contained'
                    spin={updateStarted && resolutionText === resolution}
                    spinContent={spinContent}
                    onClick={() => handlePostResolution(resolution)}>
                    {DisputationMap[resolution].text}
                  </ButtonSpinner>
                )}
              </animated.span>
            )
        }}
      </Transition>
      {!checkDisputationButtons(review.resolutions) && <Text>No escalation allowed</Text>}
    </EscalationDisputationButtonsContainer>
  )
}

export default EscalateButton

import React, { useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import moment from 'moment'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ProgressLoader from '../../common/ProgressLoader'
import RadarServiceFactory, { RadarServices } from '../../../services'

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: theme.palette.primary.light,
  },
  button: {
    color: '#FFF',
  },
  table: {
    width: '100%',
  },
  row: {
    width: '220px',
  },
  collaspeButton: {
    width: '70px',
  },
}))

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.main,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export default function ReviewTableRow({ review, selectedRows, onRowCheckChange, onRowClick }) {
  const [open, setOpen] = useState(false)
  const [fetchStarted, setFetchStarted] = useState(false)
  const closableStatuses = [
    'New',
    'RemovedAtReviewSite',
    'DoNotRespond',
    'Posted',
    'InDraft',
    'Undrafted',
    'Ready',
    'Proposed',
  ]
  const classes = useStyles()

  const handleToggleHistoryClick = () => {
    if (!open && !review.history && !fetchStarted) {
      setFetchStarted(true)
      setTimeout(async () => {
        let service = RadarServiceFactory.create(RadarServices.Reviews),
          result = await service.getReviewHistory(review.reviewId)

        review.history = result.Ok
          ? result.Content.timeLine.map(item => ({
              dateTime: item.actionDateTime,
              fullname: item.user.fullname,
              email: item.user.email,
              reason: item.action,
            }))
          : []
        setFetchStarted(false)
      }, 100)
    }

    setOpen(!open)
  }

  const ShouldShowButton = review => {
    if (review.status === 'Closed' || closableStatuses.includes(review.status))
      return (
        <Button
          disableElevation
          variant='contained'
          size='small'
          color={review.status === 'Closed' ? 'primary' : 'secondary'}
          className={classes.button}
          onClick={() => onRowClick(review)}>
          {review.status === 'Closed' ? 'ReOpen' : 'Close'}
        </Button>
      )
    return null
  }

  return (
    <>
      <StyledTableRow>
        <StyledTableCell className={classes.collaspeButton}>
          <Checkbox
            name={review.reviewId}
            color='primary'
            className={classes.checkbox}
            checked={selectedRows.includes(review.reviewId)}
            onChange={onRowCheckChange}
          />
          <IconButton aria-label='expand row' size='small' onClick={handleToggleHistoryClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align='left'>{moment(review.reviewDate).format('MM/DD/YYYY')}</StyledTableCell>
        <StyledTableCell align='left'>{review.reviewSource}</StyledTableCell>
        <StyledTableCell align='left'>{review.reviewSite}</StyledTableCell>
        <StyledTableCell align='left'>{review.clientName}</StyledTableCell>
        <StyledTableCell align='center'>{review.reviewRating}</StyledTableCell>
        <StyledTableCell align='left'>{review.reviewerName}</StyledTableCell>
        <StyledTableCell align='left'>
          {review.reviewUrl ? (
            <a href={review.reviewUrl} target='_blank' rel='noopener noreferrer'>
              Visit Site
            </a>
          ) : (
            <span>N/A</span>
          )}
        </StyledTableCell>
        <StyledTableCell align='left'>{review.status}</StyledTableCell>
        <StyledTableCell align='left'>{moment(review.ingestionDate).format('MM/DD/YYYY')}</StyledTableCell>
        <StyledTableCell align='left'>{review.sentiment}</StyledTableCell>
        <StyledTableCell align='left'>{review.lastModifiedBy}</StyledTableCell>
        <StyledTableCell align='left'>
          {review.clientName && (
            <a
              href={`/queue/${review.reviewId}${review.status === 'Closed' ? '?view=history' : ''}`}
              target='_blank'
              rel='noopener noreferrer'>
              Details
            </a>
          )}
        </StyledTableCell>
        <StyledTableCell align='left'>{ShouldShowButton(review)}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={14}>
          <Collapse in={open} timeout='auto'>
            {fetchStarted ? (
              <ProgressLoader />
            ) : (
              <Box margin={1}>
                <Typography variant='h6' gutterBottom component='div'>
                  History
                </Typography>
                <Table size='small' className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.row}>Date</TableCell>
                      <TableCell className={classes.row}>Name</TableCell>
                      <TableCell className={classes.row}>Email</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {review.history?.map(history => (
                      <TableRow key={history.dateTime}>
                        <TableCell>{moment(history.dateTime).format('MM/DD/YYYY hh:mm a')}</TableCell>
                        <TableCell>{history.fullname}</TableCell>
                        <TableCell>{history.email}</TableCell>
                        <TableCell>{history.reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

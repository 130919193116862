import styled from 'styled-components'

export const DisputationMap = {
  MarkDisputeAccepted: {
    text: 'Accepted',
    color: 'success',
    variant: 'main',
    hover: 'lighter',
    path: 'dispute-accepted',
    order: 1,
  },
  MarkDisputeDenied: {
    text: 'Denied',
    color: 'error',
    variant: 'main',
    hover: 'lighter',
    path: 'dispute-denied',
    order: 2,
  },
  Escalate: {
    text: 'Escalate',
    color: 'warning',
    variant: 'light',
    override: 'color: #FFFFFF !important;',
    path: 'escalate',
    order: 1,
  },
  Deescalate: {
    text: 'De-escalate',
    color: 'secondary',
    variant: 'main',
    path: 'deescalate',
    order: 1,
  },
  MarkDisputed: {
    text: 'Dispute',
    color: 'error',
    variant: 'light',
    override: 'color: #FFFFFF !important;',
    path: 'dispute',
    order: 2,
  },
}

export const EscalationDisputationButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
`

export const Text = styled.p`
  color: ${props => props.theme.foreground.lightest};
`

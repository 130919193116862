import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import TextEditor from '../../common/TextEditor'
import { EditorContainer, ButtonSpinnerText } from './Shared.S'
import CheckBox from '../../common/CheckBox'
import ButtonSpinner from '../../common/ButtonSpinner'
import ValidationModal from '../common/ValidationModal'

function NewTemplateEditor(props) {
  const { updateStarted, onCancel, onSave } = props
  const [responseTemplate, setResponseTemplate] = useState({ title: '', content: '', sentiments: [] })
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const showErrorDialog = msg => {
    setErrorMessage(msg)
    setShowModal(true)
  }

  const handleClose = () => setShowModal(false)

  const handleCheckClick = (e, checked) => {
    let updated = { ...responseTemplate }
    if (checked) updated.sentiments = [...updated.sentiments.flat(), e.id]
    else updated.sentiments = updated.sentiments.filter(a => a !== e.id)

    setResponseTemplate(updated)
  }

  const handleTitleChanged = e => {
    let updated = { ...responseTemplate }
    updated.title = e.target.value
    setResponseTemplate(updated)
  }

  const handleContentChanged = (data, delta, source, editor) => {
    let updated = { ...responseTemplate }
    if (editor.getText().length <= 1) data = ''
    updated.content = data
    setResponseTemplate(updated)
  }

  const handleSaveClick = () => {
    if (responseTemplate.title.length === 0) {
      showErrorDialog('Title is required please fill in this area')
      return
    }

    if (responseTemplate.sentiments.length === 0) {
      showErrorDialog('Sentiments are required please select one or more')
      return
    }

    if (responseTemplate.content.length === 0) {
      showErrorDialog('Content is required please fill in this area')
      return
    }

    onSave(responseTemplate)
  }

  return (
    <>
      <EditorContainer>
        <div className='header'>
          <p>
            <span className='bolder'>ADD NEW</span> Response Template
          </p>
          <FaTimes className='closeButton' onClick={onCancel} />
        </div>
        <div className='sentiments'>
          <TextField
            className='col-md-6'
            placeholder='Response Template Name'
            value={responseTemplate.title}
            onChange={handleTitleChanged}
            variant='outlined'
            margin='dense'
            required
          />
          <CheckBox
            id='Positive'
            onChanged={handleCheckClick}
            checked={responseTemplate.sentiments.includes('Positive')}
          />{' '}
          Positive
          <CheckBox
            id='Neutral'
            onChanged={handleCheckClick}
            checked={responseTemplate.sentiments.includes('Neutral')}
          />{' '}
          Neutral
          <CheckBox
            id='Negative'
            onChanged={handleCheckClick}
            checked={responseTemplate.sentiments.includes('Negative')}
          />{' '}
          Negative
        </div>
        <TextEditor
          placeholder='Draft Response Template HERE'
          body={responseTemplate.content}
          onChange={handleContentChanged}
        />
        <div className='controls pull-right'>
          <Button disableElevation variant='outlined' color='primary' onClick={onCancel}>
            CANCEL
          </Button>
          <ButtonSpinner
            disableElevation
            style={{ marginLeft: '20px', color: '#FFF' }}
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<ButtonSpinnerText>Saving...</ButtonSpinnerText>}
            onClick={handleSaveClick}>
            Submit
          </ButtonSpinner>
        </div>
      </EditorContainer>
      <ValidationModal show={showModal} message={errorMessage} onClose={handleClose} />
    </>
  )
}

export default NewTemplateEditor

import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import Typography from '@mui/material/Typography'
import ModeSelector from './ModeSelector'
import Account from './Account'
import Translations from '../../translations/v2/TranslationsV2'
import CrosshairImg from '../../assets/crosshair.png'
import { Nav } from './NavBar.S'

export default function NavBar(props) {
  const { location } = props
  const [tabIndex, setTabIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(-1)

  useEffect(() => {
    if (!isEmpty(location.pathname)) {
      if (location.pathname.includes('/settings')) setTabIndex(3)
      else if (location.pathname.includes('/account')) setTabIndex(2)
      else if (location.pathname.includes('/manager')) setTabIndex(1)
      else if (location.pathname.includes('/')) setTabIndex(0)
    }
  }, [location])

  return (
    <Nav className='navbar-expand-lg'>
      <div className='flex-row navbar-brand'>
        <img
          src={CrosshairImg}
          width='30'
          height='30'
          className='d-inline-block align-top'
          alt='radar-logo'
        />
      </div>
      <div className='collapse navbar-collapse'>
        <ul className='navbar-nav mr-auto'>
          {Translations.navbar.map((tab, tabId) => (
            <li
              key={tabId}
              style={{ paddingBottom: '5px', marginBottom: '-10px' }}
              className={`navitem mr-3 ${
                tabId === tabIndex
                  ? 'active das-border-bottom-active'
                  : `${hoverIndex === tabId ? 'das-border-bottom-hover' : ''}`
              }`}
              onMouseEnter={() => setHoverIndex(tabId)}
              onMouseLeave={() => setHoverIndex(-1)}>
              <NavLink to={tab.path} className='nav-link'>
                <Typography color='textPrimary'>{tab.name}</Typography>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <div className='flex-row pr-3'>
        <ModeSelector />
      </div>

      <div className='d-none d-lg-flex'>
        <Account />
      </div>
    </Nav>
  )
}

import React from 'react'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import ClientSearchInput from '../ClientSearchInput'
import CardContent from '@mui/material/CardContent'
import { FilterCard, FilterCardHeadar, useCardStyles } from './Card'

export default function ({ selected, onFilterChanged }) {
  const classes = useCardStyles()

  const handleClientSelected = client => {
    let ndx = selected.findIndex(a => a.id === client.id)
    if (ndx === -1) onFilterChanged({ filters: [...selected, client] })
  }

  const handleDeleteClientClick = client =>
    onFilterChanged({ filters: selected.filter(a => a.id !== client.id) })

  const handleClearAllClick = () => onFilterChanged({ filters: [] })

  return (
    <FilterCard style={{ overflow: 'visible' }}>
      <FilterCardHeadar title='Clients' selectAll={false} onActionClick={handleClearAllClick} />
      <CardContent className={classes.cardContent}>
        <ClientSearchInput
          paddingTop={8}
          paddingBottom={8}
          marginBottom={5}
          onClientSelected={handleClientSelected}
        />
        {selected.map(client => (
          <Chip
            data-clientid={client.id}
            className={classes.chip}
            key={client.id}
            clickable
            avatar={<Avatar className={classes.avatar}>{client.name.charAt(0)}</Avatar>}
            label={client.name}
            color='primary'
            variant='outlined'
            onDelete={() => handleDeleteClientClick(client)}
            size='small'
          />
        ))}
      </CardContent>
    </FilterCard>
  )
}

import './App.css'
import { connect } from 'react-redux'
import React from 'react'
import { Route, withRouter, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import DASTheme, { DASThemeDark } from './components/themes/default'
import NavBar from './components/navbar/NavBar'
import Notifications from './components/common/Notifications'
import QueueWorkspace from './components/queue/QueueWorkspace'
import SettingsWorkspace from './components/settings/SettingsWorkspace'
import ReviewsManagerWorkspace from './components/manager/ReviewsManagerWorkspace'
import LoginView from './components/login/LoginView'
import SessionManager, { Logout } from './components/common/SessionManager'
import ErrorBoundary from './components/common/ErrorBoundary'
import AchievementNotification from './components/common/AchievementNotification'
import { userLogoutAction } from './store/actions/user'
import AccountsWorkspace from "./components/accounts/AccountsWorkspace";
import ExportView from "./components/reports/ExportView";

const App = props => {
    const { notifyUserLogout, theme, materialTheme, userLoggedIn, location, history } = props

    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path='/login' render={() => <LoginView history={history} location={location} />} />
                <Route exact path='/export/:id/:type?' render={() => <ExportView history={history} location={location} />} />
                <Route
                    exact
                    path='/logout'
                    render={() => <Logout history={history} notifyUserLogout={notifyUserLogout} />}
                />
                <Route
                    path='/'
                    render={() => (
                        <MaterialThemeProvider theme={materialTheme}>
                            <ThemeProvider theme={theme}>
                                <SnackbarProvider
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}>
                                    <Notifications />
                                    <div
                                        className='container-fluid vh-100 d-flex flex-column p-0'
                                        style={{
                                            backgroundColor: theme.background.main,
                                            color: theme.text.primary,
                                        }}>
                                        {userLoggedIn && <AchievementNotification />}
                                        <SessionManager notifyUserLogout={notifyUserLogout} userLoggedIn={userLoggedIn} />
                                        <div className='row no-gutters'>
                                            <div className='col-sm-12'>
                                                <NavBar location={location} />
                                            </div>
                                        </div>
                                        <div className='row flex-grow-1 flex-column no-gutters'>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path='/settings'
                                                    render={() => <SettingsWorkspace history={history} />}
                                                />
                                                <Route
                                                  exact
                                                  path='/accounts'
                                                  render={() => <AccountsWorkspace history={history} />}
                                                />
                                                <Route
                                                    exact
                                                    path='/manager'
                                                    render={() => <ReviewsManagerWorkspace history={history} />}
                                                />
                                                <Route exact path='/queue/:id' render={() => <QueueWorkspace history={history} />} />
                                                <Route exact path='/queue' render={() => <QueueWorkspace history={history} />} />
                                                <Route exact path='/' render={() => <Redirect to='/queue' />} />
                                                <Route
                                                    render={() => (
                                                        <div id='app-notfound'>
                                                            <div className='app-notfound'>
                                                                <div className='app-notfound-404'></div>
                                                                <h1 className='app-heading'>404</h1>
                                                                <h2 className='app-heading'>OOPS! PAGE NOT FOUND</h2>
                                                                <p>
                                                                    Sorry but the page you are looking for does not exist or has been removed.
                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </Switch>
                                        </div>
                                    </div>
                                </SnackbarProvider>
                            </ThemeProvider>
                        </MaterialThemeProvider>
                    )}
                />
            </Switch>
        </ErrorBoundary>
    )
}

function mapStateToProps(state) {
    let theme = state.user.normalMode ? DASTheme : DASThemeDark
    return {
        userLoggedIn: state.user.loggedIn,
        user: state.user,
        theme: theme,
        materialTheme: createTheme({
            palette: theme,
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: theme.background.main,
                            overflow:'hidden'
                        }
                    }
                },
                MuiPickersDay: {
                    styleOverrides: {
                        root: {
                            backgroundColor: theme.background.dark,
                        }
                    }
                },
                MuiCalendarPicker: {
                    styleOverrides: {
                        root: {
                            "& svg": {
                                fill: theme.foreground.main,
                            }
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            "& > svg": {
                                fill: theme.foreground.main,
                            }
                        }
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            "& .MuiIconButton-root svg": {
                                fill: theme.foreground.main,
                            }
                        }
                    }
                },
                MuiTablePagination: {
                    styleOverrides: {
                        root: {
                            backgroundColor: theme.background.dark,
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& svg": {
                                fill: theme.foreground.main,
                            }
                        }
                    }
                }
            },

        }),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notifyUserLogout: () => dispatch(userLogoutAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))

import 'react-quill/dist/quill.snow.css'
import React, { useEffect, useState, useContext } from 'react'
import CreatableSelect from 'react-select/creatable'
import { useTheme } from '@mui/styles'
import { connect } from 'react-redux'
import { IoMdClipboard, IoMdCloseCircle } from 'react-icons/io'
import { FaPaperPlane } from 'react-icons/fa'
import ReviewServiceFactory, { RadarServices } from '../../../../services'
import RadarMail from '../../../../lib/mail'
import FlexContainer from '../../../common/FlexContainer'
import ProgressLoader from '../../../common/ProgressLoader'
import Button from '../../../common/Button'
import ButtonSpinner from '../../../common/ButtonSpinner'
import TextEditor from '../../../common/TextEditor'
import { EmailTemplateModal, Editor, InternalEditor, EmailSubjectLine } from './EmailTemplates.S'
import { addNotificationAction, NotificationType } from '../../../../store/actions/notifications'

function EmailTemplates(props) {
  const { templateId, draftedResponse, review, addNotification, show, onClose } = props
  const [emailBody, setEmailBody] = useState('')
  const [emailSubject, setEmailSubject] = useState('')
  const [selectedContacts, setSelectedContacts] = useState([])
  const [sendInProgress, setSendInProgress] = useState(false)
  const [sendError, setSendError] = useState(false)
  const [template, setTemplate] = useState(null)
  const [contacts, setContacts] = useState([])
  const [groups, setGroups] = useState({})
  const [fetchStarted, setFetchStarted] = useState(false)
  const [fetchContactsStarted, setFetchContactsStarted] = useState(false)
  const theme = useTheme()
  const mailer = new RadarMail()
  const savingButtonStyle = { color: '#FFF', padding: '1px' }
  const spinContent = <div style={savingButtonStyle}>Sending..</div>

  const contactsStyle = {
    control: provided => ({
      ...provided,
      width: '100%',
      marginBottom: 5,
      backgroundColor: theme.palette.background.main,
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
      backgroundColor: theme.palette.background.main,
    }),
    multiValue: styles => ({
      ...styles,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.main,
    }),
    multiValueLabel: base => ({
      ...base,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.main,
    }),
    clearIndicator: (base, state) => ({
      ...base,
      cursor: 'pointer',
      color: theme.palette.text.primary,
    }),
  }

  const ClearIndicator = props => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>
          <IoMdCloseCircle /> clear all
        </div>
      </div>
    )
  }

  const getFormattedDate = datestr => {
    let date = new Date(datestr),
      options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString('en-US', options) + ' ' + date.toLocaleTimeString('en-US')
  }

  const createReplacementVariables = () => {
    let now = new Date(),
      originalText = `${review.reviewRating}/5 stars\n
                ${review.reviewerName}\n
                ${getFormattedDate(review.reviewDate)}\n
                ${review.reviewSite ? review.reviewSite + '\n' : ''}
                ${review.reviewText ? review.reviewText.replace(/<\/?[^>]+(>|$)/g, '') : ''}`

    return {
      '[ORIGINAL_REVIEW]': originalText,
      '[PROPOSED_RESPONSE]': draftedResponse,
      '[CLIENT_NAME]': review.client.clientName,
      '[SITE]': review.reviewSite,
      '[DATE]': getFormattedDate(now),
      '[LINK]': review.reviewUrl,
      '[PUBLISHER]': review.reviewerName ?? 'REVIEWER',
    }
  }

  const replaceTemplateVariables = template => {
    let variables = createReplacementVariables(),
      keys = Object.keys(variables)
    return keys.reduce((updatedTemplate, key) => {
      let replace = `\\${key.slice(0, key.length - 1)}\\]`
      return updatedTemplate.replace(new RegExp(replace, 'g'), variables[key] || '')
    }, template)
  }

  const fetchTemplate = async templateId => {
    let service = ReviewServiceFactory.create(RadarServices.EmailTemplates),
      result = await service.getTemplate(templateId)
    if (result.Ok) {
      setTemplate(result.Content)
    }

    setFetchStarted(false)
  }

  const fetchContacts = async id => {
    let service = ReviewServiceFactory.create(RadarServices.ClientContacts),
      result = await service.getContactsByClient(id)
    if (result.Ok) {
      let contacts = []
      let groups = {}
      result.Content.forEach(i => {
        let email = i.email.toLowerCase()
        for (let groupName of i.notificationGroups) {
          if (!groups[groupName]) {
            groups[groupName] = []
            contacts.push({
              id: groupName.replace(' ', ''),
              value: groupName,
              label: groupName,
              group: true,
            })
          }
          if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) groups[groupName].push(email)
        }
      })
      setContacts(contacts)
      setGroups(groups)
    }

    setFetchContactsStarted(false)
  }

  const handleEditorChange = value => setEmailBody(value)

  const handleSubjectLineChange = e => setEmailSubject(e.target.value)

  const handleCopyClick = () => {
    let copylistener = e => {
      e.clipboardData.setData('text/html', emailBody)
      e.clipboardData.setData('text/plain', emailBody)
      e.preventDefault()
    }
    document.addEventListener('copy', copylistener)
    document.execCommand('copy')
    document.removeEventListener('copy', copylistener)

    addNotification('Notification copied to clipboard!', NotificationType.SUCCESS_NOTIFICATION)
  }

  const handleSendEmailClick = async () => {
    setSendInProgress(true)

    let recipents = selectedContacts.filter(a => !a.group).map(a => a.value)
    let groupRecipents = selectedContacts.filter(a => a.group).flatMap(a => groups[a.value])

    let retval = await mailer.sendMail({
      subject: emailSubject,
      recipients: [...recipents, ...groupRecipents],
      body: emailBody,
    })

    if (retval.Ok) {
      setSelectedContacts([])
      addNotification('Notification email sent!', NotificationType.SUCCESS_NOTIFICATION)
      onClose()
    } else {
      console.error(retval.Message)
      setSendError(true)
    }

    setSendInProgress(false)
  }

  const handleContactSelection = (option, meta) => {
    if (meta.action === 'remove-value') {
      let filtered = selectedContacts.filter(c => c.id !== meta.removedValue.id)
      setSelectedContacts(filtered)
    } else if (meta.action === 'select-option') {
      setSelectedContacts(option)
    } else if (meta.action === 'clear') {
      setSelectedContacts([])
    } else if (meta.action === 'create-option') {
      let entry = option[option.length - 1]
      if (entry.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        let contact = selectedContacts.find(f => f.value === entry.value)
        if (!contact) {
          let id = Math.random().toString(36).substr(2, 9)
          setSelectedContacts([
            { id, value: entry.value, label: entry.value, group: false },
            ...selectedContacts,
          ])
        }
      }
    }
  }

  useEffect(() => {
    if (templateId) {
      setFetchStarted(true)
      fetchTemplate(templateId)
      setFetchContactsStarted(true)
      fetchContacts(review.client.id)
      setSendError(false)
    }
  }, [templateId, review])

  useEffect(() => {
    let body = template ? replaceTemplateVariables(template.content) : ''
    setEmailBody(body)

    let subject = template ? replaceTemplateVariables(template.subject) : ''
    setEmailSubject(subject)
  }, [template])

  useEffect(() => {
    if (!fetchContactsStarted) setSelectedContacts(contacts)
  }, [contacts])

  return (
    <EmailTemplateModal id='email-templates' open={show} title='Choose Notification Groups'>
      {fetchStarted ? (
        <ProgressLoader />
      ) : !template ? (
        <div>Failed find email template</div>
      ) : (
        <>
          <CreatableSelect
            isClearable
            isMulti
            placeholder='Select or Add client contact...'
            components={{ ClearIndicator, DropdownIndicator: null }}
            closeMenuOnSelect={false}
            styles={contactsStyle}
            options={contacts}
            onChange={handleContactSelection}
            value={selectedContacts}
            theme={selTheme => ({
              ...selTheme,
              colors: {
                ...selTheme.colors,
                primary25: theme.palette.background.dark,
              },
            })}
          />
          <EmailSubjectLine>
            <input
              id='subjectLine'
              name='subjectLine'
              placeholder='Email Subject Goes Here'
              value={emailSubject}
              onChange={handleSubjectLineChange}
            />
          </EmailSubjectLine>
          <Editor>
            <TextEditor body={emailBody} onChange={handleEditorChange}>
              <InternalEditor />
            </TextEditor>
          </Editor>
        </>
      )}
      <FlexContainer>
        <Button className='col-md-2' color='background' variant='darker' onClick={onClose}>
          Close
        </Button>
        <div className='col-md-4'></div>
        <Button className='col-md-2' variant='main' color='secondary' onClick={handleCopyClick}>
          <IoMdClipboard /> Copy
        </Button>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF' }}
          className='col-md-3'
          variant='contained'
          color='primary'
          disabled={selectedContacts.length === 0 || emailSubject.length === 0 || emailBody.length === 0}
          spin={sendInProgress}
          spinContent={spinContent}
          onClick={handleSendEmailClick}>
          <FaPaperPlane />
          <span>&nbsp;&nbsp;Send Email </span>
        </ButtonSpinner>
      </FlexContainer>
      {sendError && (
        <FlexContainer className='error-bg' style={{ marginTop: 5, padding: 4 }}>
          <span className='small-header'>An error occured sending email.</span>
        </FlexContainer>
      )}
    </EmailTemplateModal>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, status) => dispatch(addNotificationAction(text, status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)

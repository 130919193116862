import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useFlagsmith } from 'flagsmith-react'
import AuthService from '../../lib/auth'
import RadarSession from '../../lib/session'
import { userLoginAction } from '../../store/actions/user'
import LogoImg from '../../assets/logo.png'
import PageProgressBar from '../common/ProgressPageLoader'
import {
  MarketingContainer,
  LogInContainer,
  Logo,
  LogInButton,
  FlexContainer,
  Spinner,
  ErrorMessage,
} from './LoginView.S'

function LoginView(props) {
  const UNHANDLED_ERROR =
    'An unexpected error occured authenticating your credentials please contact the engineers with this error'

  const { isIdentified, identify } = useFlagsmith()
  const { history, dispatch, userLoggedIn, user, location } = props
  const [errorMessage, setErrorMessage] = useState('')
  const [loginProgress, setLoginProgress] = useState(false)
  const authService = new AuthService()

  const handleAuthentication = result => {
    if (result.Ok) {
      let session = new RadarSession()
      session.setSessionState(result.Content)

      dispatch(userLoginAction(result.Content.profile))
    } else {
      console.error(result.Message)
      setErrorMessage(UNHANDLED_ERROR)
    }

    setLoginProgress(false)
  }

  const handleLoginClick = async () => {
    if (!loginProgress) {
      authService.signIn()
      setLoginProgress(true)
    }
  }

  useEffect(() => {
    async function checkFlags() {
      if (userLoggedIn) {
        await identify(user.profile.username, { name: user.profile.fullname })
      }
    }
    checkFlags()
  }, [userLoggedIn])

  useEffect(() => {
    if (location.hash && location.hash.startsWith('#code')) authService.handleRedirect(handleAuthentication)
  }, [location])

  useEffect(() => {
    if (isIdentified && userLoggedIn) {
      if (userLoggedIn) history.push('/queue')
    }
  }, [isIdentified, userLoggedIn])

  return (
    <FlexContainer justifyContent='stretch'>
      {loginProgress && <PageProgressBar />}
      <MarketingContainer />
      <LogInContainer>
        <FlexContainer margin='0 12% 0px 12%' width='auto' direction='column'>
          <Logo src={LogoImg} />
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <LogInButton onClick={() => handleLoginClick()}>
            {loginProgress ? (
              <>
                <Spinner /> <span>Please wait</span>
              </>
            ) : (
              <span>Log in</span>
            )}
          </LogInButton>
          <p>
            Our AI-powered chat technology, Response Path, increases website conversions and engages on
            multiple platforms—not just your website. Response Path starts conversations on Facebook
            Messenger, via text message, and on your website asking qualifying questions and alerting your
            team members once extra assistance is needed. Click here to learn why Response Path was named
            Favorite New Product of 2018 or contact your Client Advocate today.
          </p>
        </FlexContainer>
      </LogInContainer>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    userLoggedIn: state.user.loggedIn,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(LoginView)

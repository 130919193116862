import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

const statusColors = {
  donotrespond: ['success', 'main'],
  removedatreviewsite: ['success', 'main'],
  posted: ['success', 'main'],
  indraft: ['secondary', 'main'],
  proposed: ['secondary', 'main'],
  ready: ['secondary', 'dark'],
  undrafted: ['primary', 'dark'],
  closed: ['success', 'dark'],
  new: ['primary', 'light'],
}

export const useStyles = makeStyles(theme => ({
  avatar: {
    color: '#FFF !important',
    backgroundColor: '#FE9143',
    border: `2px solid ${theme.palette.background.main}`,
  },
  timeline: { padding: '0' },
  timelineItem: {
    maxWidth: '750px',
    minWidth: '400px',
    marginLeft: theme.spacing(2),
  },
  timelineItemStatus: {
    height: '30px',
    paddingTop: theme.spacing(2.3),
  },
  timelineItemDot: {
    padding: 0,
    marginLeft: theme.spacing(0.2),
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  connector: {
    borderStyle: 'dashed',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    borderColor: theme.palette.type === 'dark' ? theme.palette.foreground.lightest : '#EAE6E3',
    marginLeft: '13px',
  },

  closedEvent: {
    border: `solid 2px ${theme.palette.success.main}`,
  },
  onlineComment: {
    color: theme.palette.secondary.main,
    marginLeft: '5px',
    marginTop: '-7px',
  },
  collaspe: {
    paddingLeft: '19px',
  },
}))

export const Subheader = styled.div`
  color: #ffffffbb;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  text-align: left;
  margin: 0;
`

export const ReviewAction = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;

  > div {
    padding: 10px;
    width: 100%;
    background-color: ${props =>
      props.theme.type === 'dark' ? props.theme.background.dark : 'rgba(77,77,77,0.04)'};
    margin-bottom: 10px;
    border-radius: 12px;
  }
`
export const ReviewActionHeader = styled.div`
  color: ${props => (props.theme.type === 'dark' ? '#ffffffbb' : '#0000006F')};
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin: 0;
`

export const StatusIndicator = styled.div`
  display: inline-block;
  color: #fff;
  text-align: center;
  background-color: ${props => props.theme[statusColors[props.status][0]][statusColors[props.status][1]]};
  border-radius: 3px;
  font-size: 12px;
  width: 130px;
  padding: 1px;
  margin-left: 10px;
`

export const StatusKeyword = styled.span`
  color: ${props => props.theme[statusColors[props.status][0]][statusColors[props.status][1]]};
  font-weight: 700;
`

export const Comment = styled.div`
  padding: 10px;
  margin-left: 10px;
  background-color: ${props => props.theme.background.dark};
  border-radius: 5px;
  border-top-left-radius: 0;
  font-size: 11px;
  font-family: 'Roboto';
  color: ${props => props.theme.text.primary};

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    float: left;
  }

  span {
    font-size: 16px;
  }
`

export const ContentBubble = styled.div`
  position: relative;
  margin-top: 10px;
  max-width: 750px;
  color: #fff;
  background-color: ${props =>
    props.theme.type === 'dark' ? props.theme.secondary.main + '41' : 'rgba(0,150,219,0.8)'};
  padding: 1.125em 1.5em;
  font-size: 1.25em;
  border-radius: 1rem;
  //box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: ${props =>
      props.theme.type === 'dark' ? props.theme.secondary.main + '41' : 'rgba(0,150,219,0.8)'};
    //filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`

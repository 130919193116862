import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import RadarServiceFactory, { RadarServices } from '../../../services'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { BsSearch } from 'react-icons/bs'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'
import { makeStyles, useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import HelpIcon from '@mui/icons-material/Help'
import Title from '../../common/Title'
import ButtonSpinner from '../../common/ButtonSpinner'
import SimpleModal from '../../common/SimpleModal'
import FlexContainer from '../../common/FlexContainer'
import Tooltip from '../../common/Tooltip'
import ProgressLoader from '../../common/ProgressLoader'
import TextField from '@mui/material/TextField'
import {
  BodyText,
  Container,
  TagsContainer,
  IndicatorContainer,
  IndicatorDot,
  UpdatingText,
  SearchIndicator,
  MenuItem,
  Tags,
  ReviewResponseDateContainer,
} from './Account.S'
import MomentUtils from '@date-io/moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  avatar: {
    color: '#FFF !important',
  },
  editbutton: {
    marginTop: theme.spacing(2),
  },
  tagstitle: {
    marginTop: theme.spacing(5),
  },
  datepicker: {
    '& .MuiInput-input': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}))

const UPDATE_TYPE = { ACCOUNT: 'account', RESPONSE_DATE: 'response-date' }

function Account(props) {
  const { account, updateStarted, saveAccount, updatedAccount, addNotification } = props
  const [accountEnabled, setAccountEnabled] = useState(false)
  const [reviewResponseDate, setReviewResponseDate] = useState(null)
  const [selectedTag, setSelectedTag] = useState(null)
  const [tags, setTags] = useState([])
  const [tagUpdateStarted, setTagUpdateStarted] = useState(false)
  const [disableAssign, setDisableAssign] = useState(true)
  const [fetchStarted, setFetchStarted] = useState(true)
  const [deleteStarted, setDeleteStarted] = useState(false)
  const [modal, setModal] = useState({})
  const [edit, setEdit] = useState(false)
  const [updateType, setUpdateType] = useState(null)
  const timer = useRef(0)
  const classes = useStyles()
  const theme = useTheme()
  const helpText =
    'The start date when new reviews can be received into Radar for this account. Empty date disables this feature.'

  const searchForTags = async (query, resolve) => {
    if (!query || query.length === 0) return

    let timeoutVal = timer.current,
      tags = [],
      service = RadarServiceFactory.create(RadarServices.Tags)

    if (timeoutVal !== timer.current || timer.current === -1) {
      console.log(`timeoutVal : ${timeoutVal}, timer : ${timer.current}`)
      return
    }

    let result = await service.getTags({ name: query, count: 100 })
    if (result.Ok) {
      tags = result.Content.tags.map(tag => ({
        label: tag.displayName,
        value: tag,
      }))
    }

    if (timeoutVal === timer.current && timer.current !== -1) resolve(tags)
  }

  const fetchTags = async () => {
    let service = RadarServiceFactory.create(RadarServices.Tags),
      retval = await service.getTags({ clients: [account.clientId] })
    if (retval.Ok) setTags(retval.Content.tags)

    setFetchStarted(false)
  }

  const saveTag = async (tag, onCompleted) => {
    let service = RadarServiceFactory.create(RadarServices.Tags),
      result = await service.updateTag(tag)
    if (result.Ok) {
      addNotification({
        type: NotificationType.SUCCESS_NOTIFICATION,
        text: 'Update completed successfully',
      })
    } else {
      addNotification({
        type: NotificationType.ERROR_NOTIFICATION,
        text: 'Update failed miserably.. Go tell someone please',
      })
    }

    onCompleted(result)
  }

  const handleUpdateClick = () => {
    if (!updateStarted) {
      setUpdateType(UPDATE_TYPE.ACCOUNT)
      saveAccount(account.clientId, { enabled: !accountEnabled })
    }
  }

  const handleLoadTags = val => {
    return new Promise(resolve => {
      if (val.length < 2) resolve([])
      clearTimeout(timer.current)
      timer.current = -1
      timer.current = setTimeout(() => searchForTags(val, resolve), 1000)
    })
  }

  const handleTagsChange = (option, actionType) => {
    if (actionType.action === 'select-option') {
      setSelectedTag(option.value)
      setDisableAssign(tags.some(a => a.tagId === option.value.tagId))
    } else if (actionType.action === 'clear') {
      setSelectedTag(null)
      setDisableAssign(true)
    }
  }

  const handleAssignTagClick = () => {
    if (!tagUpdateStarted) {
      let client = {
        clientId: account.clientId,
        clientName: account.clientName,
      }
      selectedTag.clients.push(client)

      setTagUpdateStarted(true)
      saveTag(selectedTag, result => {
        if (result.Ok) {
          setTags([...tags, selectedTag])
          setDisableAssign(true)
        }
        setTagUpdateStarted(false)
      })
    }
  }

  const handleDeleteClick = tag =>
    setModal({
      showModal: true,
      content: `Are you sure that you want to delete "${tag.displayName}" tag?`,
      handleModalClick: () => {
        setDeleteStarted(true)
        tag.clients = tag.clients.filter(a => a.clientId !== account.clientId)
        saveTag(tag, result => {
          if (result.Ok) setTags(tags.filter(a => a.tagId !== tag.tagId))
          setDeleteStarted(false)
          setModal({ showModal: false })
        })
      },
    })

  const handleReviewResponseUpdateClick = () => {
    if (!updateStarted) {
      setUpdateType(UPDATE_TYPE.RESPONSE_DATE)
      saveAccount(account.clientId, {
        updateReviewAutoCloseCutOffDate: true,
        reviewAutoCloseCutOffDate: reviewResponseDate,
      })
    }
  }

  const reactSelectStyles = {
    control: provided => ({ ...provided, width: 400, height: 50, borderWidth: 1 }),
    menu: base => ({ ...base, minWidth: 340, marginBottom: 76 }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: theme.palette.background.main,
      ':hover': {
        backgroundColor: theme.palette.background.dark,
      },
    }),
  }

  const SearchIndicatorContainer = props => (
    <SearchIndicator>
      <BsSearch />
    </SearchIndicator>
  )

  const MenuItemContainer = props => (
    <components.Option {...props}>
      <MenuItem className='row p-1 hand-cursor no-gutters'>
        <div>{props.data.value.displayName}</div>
      </MenuItem>
    </components.Option>
  )
  const handleReviewResponseFromDateChange = date => {
    let cDate = null
    if (date) cDate = date.format('YYYY-MM-DDT00:00:00')
    setReviewResponseDate(cDate)
    // setSearchCriteria({ ...searchCriteria, cDate })
}
  useEffect(() => {
    if (updatedAccount?.succeeded && updateType === UPDATE_TYPE.ACCOUNT) {
      setAccountEnabled(updatedAccount.content.enabled)
    }
  }, [updatedAccount])

  useEffect(() => {
    setAccountEnabled(account.enabled)
    setReviewResponseDate(account.reviewAutoCloseCutOffDate)
    fetchTags()
  }, [account])

  return (
    <>
      <Container data-client-id={account.clientId}>
        <IndicatorContainer enabled={accountEnabled}>
          <Title color={theme.palette.text.secondary}>{accountEnabled ? 'Enabled' : 'Disabled'}</Title>
          <IndicatorDot enabled={accountEnabled} />
        </IndicatorContainer>
        <BodyText>
          This feature is currently <strong>{accountEnabled ? 'enabled' : 'disabled'}</strong> for this
          account.
          <br />
          To {accountEnabled ? 'disable' : 'enable'} it, click the button below.
        </BodyText>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF', backgroundColor: theme.palette[accountEnabled ? 'error' : 'success'].main }}
          width='150px'
          variant='contained'
          spin={updateStarted && updateType === UPDATE_TYPE.ACCOUNT}
          spinContent={<UpdatingText>Updating...</UpdatingText>}
          onClick={handleUpdateClick}>
          <span>{accountEnabled ? 'Disable' : 'Enable'} Account</span>
        </ButtonSpinner>
        <ReviewResponseDateContainer>
          <span>Enable Review Responses From:</span>
          <Tooltip tooltip={helpText} className='pl-1' style={{}}>
            <HelpIcon color='primary' />
          </Tooltip>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className='d-flex mt-3' style={{ marginTop: 9 }}>
                        <DatePicker
                            label='Date:'
                            value={reviewResponseDate}
                            onChange={handleReviewResponseFromDateChange}
                            inputFormat='MM/DD/yyyy'
                            renderInput={params => <TextField {...params} />}
                        />
                    <ButtonSpinner
              variant='contained'
              style={{ color: '#FFF',margin:'0px 0 0px 20px',minWidth:150}}
              spinnerProps={{ margin: '2px 0 0 -8px' }}
              spinContent={<UpdatingText>Updating...</UpdatingText>}
              spin={updateStarted && updateType === UPDATE_TYPE.RESPONSE_DATE}
              onClick={handleReviewResponseUpdateClick}>
              <span>Update</span>
            </ButtonSpinner>
                    </div>
                    </LocalizationProvider>
          <div style={{ marginTop: '20px' }}>

          </div>
        </ReviewResponseDateContainer>
        <div className={classes.tagstitle}>
          <Title color={theme.palette.text.secondary}> Tags</Title>
        </div>
        {fetchStarted ? (
          <ProgressLoader />
        ) : (
          <div className='d-flex flex-row'>
            <IconButton color='primary' className={classes.editbutton} onClick={() => setEdit(!edit)}>
              <EditIcon />
            </IconButton>
            {!edit && (
              <Tags>
                {tags.map(tag => (
                  <Chip
                    key={tag.tagId}
                    className={classes.chip}
                    avatar={<Avatar className={classes.avatar}>{tag.displayName.charAt(0)}</Avatar>}
                    label={tag.displayName}
                    color='primary'
                    variant='outlined'
                    size='medium'
                  />
                ))}
              </Tags>
            )}
          </div>
        )}
        {edit && (
          <TagsContainer>
            <BodyText>Add or remove Tags associated with this account.</BodyText>
            <div className='d-flex flex-row'>
              <AsyncSelect
                cacheOptions
                loadOptions={handleLoadTags}
                defaultOptions
                autoFocus={false}
                isMulti={false}
                styles={reactSelectStyles}
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                components={{
                  Option: MenuItemContainer,
                  DropdownIndicator: SearchIndicatorContainer,
                  IndicatorSeparator: null,
                }}
                isClearable={true}
                placeholder='Search for Tags...'
                noOptionsMessage={() => 'No Tags Found'}
                loadingMessage={() => 'Searching for Tags...'}
                onChange={handleTagsChange}
              />
              <ButtonSpinner
                disabled={disableAssign}
                className='btn das-primary-bg text-white'
                spin={tagUpdateStarted}
                spinContent={<UpdatingText>Assigning..</UpdatingText>}
                spinnerProps={{ margin: '2px 0 0 -8px' }}
                onClick={handleAssignTagClick}>
                Assign
              </ButtonSpinner>
            </div>
            <Tags>
              {tags.map(tag => (
                <Chip
                  key={tag.tagId}
                  className={classes.chip}
                  avatar={<Avatar className={classes.avatar}>{tag.displayName.charAt(0)}</Avatar>}
                  label={tag.displayName}
                  color='primary'
                  variant='outlined'
                  size='medium'
                  onDelete={() => handleDeleteClick(tag)}
                />
              ))}
            </Tags>
          </TagsContainer>
        )}
      </Container>
      <SimpleModal title='Delete Tag' open={modal.showModal}>
        <FlexContainer>
          <BodyText>{modal.content}</BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='outlined' onClick={() => setModal({ showModal: false })}>
            No
          </Button>
          <ButtonSpinner
            className='col-md-3'
            color='error'
            variant='main'
            spin={deleteStarted}
            spinContent={<UpdatingText>Deleting..</UpdatingText>}
            spinnerProps={{ margin: '2px 0 0 -8px' }}
            onClick={modal.handleModalClick}>
            DELETE TAG
          </ButtonSpinner>
        </FlexContainer>
      </SimpleModal>
    </>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
    addNotification: notification => {
      dispatch(addNotificationAction(notification.text, notification.type))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)

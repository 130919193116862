import Service from './service'
import Globals from './global'

class ResponseTemplatesService extends Service {
  async getTemplates(page, count) {
    let retval = {},
      url = Globals.toURL('/templates/response', { page, count })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTemplatesForClient(clientId) {
    let retval = {},
      url = Globals.toURL('/templates/responseForClient', { clientId })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async createTemplate(template) {
    let retval = {},
      url = Globals.toURL('/templates/response')
    try {
      retval = await this.post(url, template)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async usageTemplate(templateId,clientId) {
    let retval = {},
      url = Globals.toURL(`/templates/usage/${templateId}`)
    try {
      retval = await this.post(url, {clientId:clientId})
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async updateTemplate(template) {
    let retval = {},
      url = Globals.toURL(`/templates/response/${template.templateId}`)
    try {
      retval = await this.put(url, template)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async deleteTemplate(template) {
    let retval = {},
      url = Globals.toURL(`/templates/response/${template.templateId}`)
    try {
      retval = await this.delete(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const ResponseTemplatesServiceFactory = function () {}
ResponseTemplatesServiceFactory.prototype.create = () => new ResponseTemplatesService()

export default ResponseTemplatesServiceFactory

import 'bootstrap/dist/css/bootstrap.css'
import './css/das.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { FlagsmithProvider } from 'flagsmith-react'
import * as FullStory from '@fullstory/browser'
import App from './App'
import configureStore from './store/configureStore'
import flagsmith from 'flagsmith'
// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const history = createBrowserHistory({ basename: baseUrl })
window.History = history
window.ApiUrl = window.location.origin + '/api/v1/'

// Flagsmith
let flagsmithEnvId = 'NE5Hz8PJddByJoqo4guWLp'

// Set the environment for radar
let env = window.location.hostname.match(/(localhost|test|dev|qa)/g)
if (env && env.length > 0) {
    window.RADAR_ENV = env[0]
    switch (window.RADAR_ENV) {
        case 'test':
            flagsmithEnvId = 'hPuqYhCfT4d8rrGnxb64SL'
            break
        case 'qa':
            flagsmithEnvId = 'UWq38rjbvRGRgkooxtmS5X'
            break
        default:
            flagsmithEnvId = 'dMd3DKR85KmEM5rZh6jU28'
            break
    }
}

const store = configureStore(history)
const rootElement = document.getElementById('root')

// Full Story Integration
FullStory.init({ orgId: '13E4CE' })
FullStory.shutdown()
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history}>
      <FlagsmithProvider environmentId={flagsmithEnvId} api='https://edge.api.flagsmith.com/api/v1/'>
        <App />
      </FlagsmithProvider>
    </ConnectedRouter>
  </Provider>,
  rootElement
)

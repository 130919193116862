import React, { useState, useEffect } from 'react'
import { MdError } from 'react-icons/md'
import { makeStyles, useTheme } from '@mui/styles'
import CircularProgressBar from '../CircularProgressBar'
import SLATimer from '../SLATimer'

const useStyles = makeStyles(theme => ({
  normal: {
    color: theme.palette.text.primary.main,
  },
  overdue: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
}))

function SLAIndicator({ review, centeredText, size, strokeWidth }) {
  const [timeText, setTimeText] = useState(null)
  const [percentage, setPercentage] = useState(-1)
  const [description, setDescription] = useState(null)
  const [responded, setResponded] = useState(false)
  const classes = useStyles()
  const theme = useTheme()

  const getDescriptionText = (percentage, responded) => {
    if (!centeredText || responded) {
      return ''
    } else if (percentage < 100) {
      return 'remaining'
    } else {
      return 'overdue'
    }
  }

  const isOverdue = () => percentage >= 100

  const onUpdateView = update => {
    setPercentage(update.percentage)
    setResponded(update.hasResponded)
    setTimeText(update.timeText)
    setDescription(getDescriptionText(update.percentage, update.hasResponded))
  }

  return (
    <div>
      <SLATimer review={review} onUpdate={onUpdateView} />
      {!centeredText && !responded && (
        <span className={isOverdue() ? classes.overdue : classes.className}> {timeText} </span>
      )}
      {!centeredText && !responded && isOverdue() ? (
        <>
          <span className={classes.overdue}>overdue</span>
          <MdError color={theme.palette.error.main} size='23px' />
        </>
      ) : (
        <CircularProgressBar
          isResponded={responded}
          strokeWidth={strokeWidth}
          sqSize={size}
          percentage={responded ? 100 : percentage}
          timeText={timeText}
          descriptionText={description}
        />
      )}
    </div>
  )
}

export default SLAIndicator

import './SettingsAccount.css'
import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { useTheme, withStyles, makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/lab/Skeleton'
import Account from './AccountComponents/Account'
import Sentiment from './AccountComponents/Sentiment'
import ReviewSites from './AccountComponents/ReviewSite'
import Notes from './AccountComponents/Notes'
import Responses from './AccountComponents/Responses'
import Signatures from './AccountComponents/Signatures'
import Contacts from './AccountComponents/Contacts'
import Title from '../common/Title'
import FlexContainer from '../common/FlexContainer'
import Translations from '../../translations/v2/TranslationsV2.json'
import { tabSelectedAction, clearNotificationAction } from '../../store/actions/account'
import { addNotificationAction } from '../../store/actions/notifications'
import AstroImage from '../../assets/astro2.png'
import AstroImageDM from '../../assets/astro2-dm.png'

import {
  Container,
  HrLineBreak,
  TabContainer,
  TabItemContainer,
  TitleContainer,
  ConfiguratorContainer,
} from './SettingsAccount.S'

const useStyles = makeStyles(theme => ({
  text: {
    height: '76px',
    width: '375.36px',
    opacity: theme.palette.type === 'dark' ? 0.9 : 0.5,
    color: theme.palette.type === 'dark' ? theme.palette.text.main : theme.palette.text.primary,
    fontSize: '26px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '32px',
    textAlign: 'center',
  },
}))

const SkeletonLoader = withStyles(theme => ({
  root: {
    height: '40px',
    width: '600px',
  },
}))(Skeleton)

function SettingsAccount(props) {
  const {
    account,
    activeTabId,
    activeTabChanged,
    fetchStarted,
    addNotification,
    clearNotification,
    notification,
    updateStarted,
  } = props
  const themeContext = useContext(ThemeContext)
  const [activeTab, setActiveTab] = useState(activeTabId)
  const theme = useTheme()
  const classes = useStyles()
  const centeredContentClass =
    'col-lg-12 col-md-12 d-flex flex-column justify-content-center align-items-center'

  const EmptySection = () => (
    <div className={centeredContentClass} style={{ marginTop: '5%' }}>
      <img src={theme.palette.type === 'dark' ? AstroImageDM : AstroImage} alt='' />
      <Typography className={`pt-3 ${classes.text}`} variant='h5'>
        Nothing happening right now try searching for an account
      </Typography>
    </div>
  )

  const Sidebar = () => (
    <Container>
      <TabContainer>
        {Translations.accountNav.map((tab, index) =>
          tab.title ? (
            <TabItemContainer
              key={index}
              selected={activeTab === index}
              onClick={() => handleTabItemClick(index)}>
              <span>{tab.title}</span>
            </TabItemContainer>
          ) : (
            <HrLineBreak key={index} />
          )
        )}
      </TabContainer>
    </Container>
  )

  const LoadingAccount = () => (
    <div className={centeredContentClass} style={{ marginTop: '10%' }}>
      <SkeletonLoader />
      <SkeletonLoader />
      <SkeletonLoader />
      <Typography variant='h5' className='pt-3'>
        Please wait while we fetch the account...
      </Typography>
    </div>
  )

  const getActiveView = () => {
    let navItem = Translations.accountNav[activeTab]
    switch (navItem.viewName) {
      case 'sentiment':
        return <Sentiment />
      case 'reviewsites':
        return <ReviewSites />
      case 'notes':
        return <Notes />
      case 'responses':
        return <Responses />
      case 'signatures':
        return <Signatures />
      case 'contacts':
        return <Contacts />
      default:
        return <Account />
    }
  }

  const handleTabItemClick = index => {
    setActiveTab(index)
    activeTabChanged(index)
  }

  useEffect(() => {
    if (!updateStarted && notification) {
      setTimeout(() => addNotification(notification), 500)
      clearNotification()
    }
  }, [updateStarted])

  return (
    <>
      {fetchStarted ? (
        <LoadingAccount />
      ) : account ? (
        <FlexContainer
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
          padding='15px 15px 0px'
          alignItems='baseline'
          height='calc(100vh - 120px)'
          addProps='overflow-x: hidden; overflow-y: auto'>
          <div>
            <TitleContainer>
              <Title color={themeContext.text.primary}>{account.clientName}</Title>
            </TitleContainer>
            <ConfiguratorContainer>
              <Sidebar />
              <ConfiguratorContainer>{getActiveView()}</ConfiguratorContainer>
            </ConfiguratorContainer>
          </div>
        </FlexContainer>
      ) : (
        <EmptySection />
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    fetchStarted: state.account.fetchStarted,
    activeTabId: state.account.activeTabId,
    updateStarted: state.account.updateStarted,
    notification: state.account.notification,
  }
}

function mapDispatchProps(dispatch) {
  return {
    activeTabChanged: tabId => dispatch(tabSelectedAction(tabId)),
    addNotification: notification => dispatch(addNotificationAction(notification.text, notification.type)),
    clearNotification: () => dispatch(clearNotificationAction()),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(SettingsAccount)

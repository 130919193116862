import React, { useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import moment from 'moment'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ProgressLoader from '../../common/ProgressLoader'
import RadarServiceFactory, { RadarServices } from '../../../services'

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: theme.palette.primary.light,
  },
  button: {
    color: '#FFF',
  },
  table: {
    width: '100%',
  },
  row: {
    width: '220px',
  },
  collaspeButton: {
    width: '70px',
  },
}))

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.main,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export default function AccountsTableRow({ model, selectedRows, onRowCheckChange, onRowClick }) {
  const [open, setOpen] = useState(false)
  const [fetchStarted, setFetchStarted] = useState(false)
  const closableStatuses = [
    'New',
    'RemovedAtReviewSite',
    'DoNotRespond',
    'Posted',
    'InDraft',
    'Undrafted',
    'Ready',
    'Proposed',
  ]
  const classes = useStyles()

  const handleToggleHistoryClick = () => {
    if (!open && !model.history && !fetchStarted) {
      setFetchStarted(true)
      setTimeout(async () => {
        let service = RadarServiceFactory.create(RadarServices.Reviews),
          result = await service.getReviewHistory(model.id)

        model.history = result.Ok
          ? result.Content.timeLine.map(item => ({
            dateTime: item.actionDateTime,
            fullname: item.user.fullname,
            email: item.user.email,
            reason: item.action,
          }))
          : []
        setFetchStarted(false)
      }, 100)
    }

    setOpen(!open)
  }

  return (
    <>
      <StyledTableRow>
        <StyledTableCell className={classes.collaspeButton}>
          <Checkbox
            name={model.id}
            color='primary'
            className={classes.checkbox}
            checked={selectedRows.includes(model.id)}
            onChange={onRowCheckChange}
          />
        </StyledTableCell>
        <StyledTableCell align='left'>{model.client.clientName}</StyledTableCell>
        <StyledTableCell align='left'>{model.clientId}</StyledTableCell>
        <StyledTableCell align='left'>{model.enabled?'Active':'InActive'}</StyledTableCell>
        <StyledTableCell align='left'>{model.reviewSite}</StyledTableCell>
        <StyledTableCell align='left'>{model.sentiment}</StyledTableCell>
        <StyledTableCell align='left'>{model.proposal}</StyledTableCell>
        <StyledTableCell align='left'>{model.tag}</StyledTableCell>
        <StyledTableCell align='left'></StyledTableCell>
      </StyledTableRow>
    </>
  )
}

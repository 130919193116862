import React, { useEffect, useState } from 'react'
import { FaPaperPlane } from 'react-icons/fa'
import { BsChatQuoteFill } from 'react-icons/bs'
import { useTheme } from '@mui/styles'
import Rating from '@mui/lab/Rating'
import SLATimer from '../../common/SLATimer'
import { QueueCardView, QueueClientName, QueueStatus, QueueTime } from './QueueCard.S'

const statusColors = {
  donotrespond: ['success', 'main'],
  removedatreviewsite: ['success', 'main'],
  posted: ['success', 'main'],
  indraft: ['secondary', 'main'],
  proposed: ['secondary', 'main'],
  ready: ['secondary', 'dark'],
  undrafted: ['primary', 'dark'],
  closed: ['success', 'dark'],
}

function QueueCard({ review, updatedReview, isDefaultTheme }) {
  const [timeText, setTimeText] = useState(null)
  const [reviewStatus, setReviewStatus] = useState('')
  const [publishColor, setPublishColor] = useState('')
  const [statusColor, setStatusColor] = useState(null)
  const [responded, setResponded] = useState(false)
  const [percentage, setPercentage] = useState(-1)
  const [activeReview, setActiveReview] = useState(false)
  const theme = useTheme()

  const getStatusColor = (percentage, responded) => {
    if (responded) {
      return theme.palette.success.main
    } else {
      if (percentage <= 50) return theme.palette.secondary.main
      else if (percentage <= 75) return theme.palette.warning.main
      else if (percentage <= 100) return theme.palette.error.main
      else return theme.palette.error.dark
    }
  }

  const isOverdue = () => percentage >= 100

  const hasResponded = review =>
    ['Posted', 'RemovedAtReviewSite', 'DoNotRespond', 'Closed', 'Ready'].includes(review.status)

  const setCardStatus = review => {
    let responded = hasResponded(review)
    setReviewStatus(review.status)
    setResponded(responded)
    if (responded) setStatusColor(theme.palette.success.main)
  }

  const onUpdateView = updates => {
    setStatusColor(getStatusColor(updates.percentage, updates.hasResponded))
    setResponded(updates.hasResponded)
    setTimeText(updates.timeText)
    setPercentage(updates.percentage)
  }

  useEffect(() => {
    if (updatedReview) {
      let active = updatedReview.reviewId == review.reviewId
      setActiveReview(active)
      if (active) setCardStatus(updatedReview)
    }
  }, [updatedReview])

  useState(() => {
    setCardStatus(review)
  }, [review])

  useEffect(() => {
    setPublishColor(isDefaultTheme ? '#0aa1e2' : '#10aff3')
  }, [isDefaultTheme])

  return (
    <>
      {!responded && <SLATimer review={review} onUpdate={onUpdateView} />}
      <QueueCardView active={activeReview} statusColor={statusColor}>
        <div className='row'>
          <div className='col-sm-6'>
            <QueueStatus statusColor={statusColors[reviewStatus.toLowerCase()]}>{reviewStatus}</QueueStatus>
            {review.hasResponses && (
              <BsChatQuoteFill color={theme.palette.secondary.main} style={{ marginLeft: '5px' }} />
            )}
          </div>
          <div className='col-sm-6'>
            {!responded && (
              <QueueTime bold={isOverdue()} active={activeReview}>
                {timeText} {isOverdue() ? 'overdue' : ''}
              </QueueTime>
            )}
          </div>
        </div>
        <div className='row pt-2'>
          <div className='col-sm-12'>
            <QueueClientName active={activeReview}>{review.clientName}</QueueClientName>
          </div>
        </div>
        <div className='row pt-2'>
          <div className='col-sm-3'>
            <Rating value={review.reviewRating} precision={0.1} size='small' readOnly />
          </div>
          <div className='col-sm-9'>
            {review.canPublishDirectly && (
              <FaPaperPlane color={publishColor} size='1.0em' style={{ float: 'right' }} />
            )}
            <span className='social-src'>{review.reviewSite}</span>
          </div>
        </div>
      </QueueCardView>
    </>
  )
}

export default QueueCard

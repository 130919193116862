import 'react-circular-progressbar/dist/styles.css'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'
import QueueListItem from './SidebarComponents/QueueListItem'
import ProgressLoader from '../common/ProgressLoader'
import PageProgressBar from '../common/ProgressPageLoader'
import { SaveActionTypes } from '../../store/actions/review'
import { useTheme } from '@mui/styles'
import Pagination from '@mui/lab/Pagination'
import { PagerView, ScrollProgressBar, useMakeStyles } from './QueueSidebar.S'
import ReviewServiceFactory, { RadarServices } from '../../services'
import { ActionIDs, pagedItemSelectionAction } from '../../store/actions/reviews'

function QueueSidebar(props) {
  const {
    review,
    page,
    pageSize,
    fetchQuery,
    pagedItemSelected,
    history,
    showFilter,
    updateWorkspace,
    clearQueue,
    activeFolderId,
  } = props
  const [sidebarReviews, setSidebarReviews] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [fetchStarted, setFetchStarted] = useState(false)
  const scrollbarDiv = useRef(null)
  const sidebarDiv = useRef(null)
  const urlParams = useLocation()
  const classes = useMakeStyles()
  const theme = useTheme()

  const fetchReviews = async (page, pageSize, query) => {
    setFetchStarted(true)
    let service = ReviewServiceFactory.create(RadarServices.Reviews),
      feedback = await service.getQueueReviews(page, pageSize, query),
      result = feedback.Ok ? feedback.Content : { reviews: [], totalCount: 0 }

    setSidebarReviews(result.reviews)
    setNumberOfPages(Math.ceil(result.totalCount / pageSize))
    setFetchStarted(false)
  }

  const addToQueue = review => {
    let ndx = sidebarReviews.findIndex(r => r.reviewId === review.reviewId)
    if (ndx === -1) {
      // We could do a routine to insert the review in a specific
      // spot (like when the review is ordered) however we are going
      // add it to the beginning of the list for now.
      sidebarReviews.push(review)
      setSidebarReviews([...sidebarReviews])
    }
  }

  const removeFromQueue = review => {
    let ndx = sidebarReviews.findIndex(r => r.reviewId === review.reviewId)
    if (ndx !== -1) {
      sidebarReviews.splice(ndx, 1)
      setSidebarReviews([...sidebarReviews])
      // Determine if we are viewing a review versus closing a review. If it
      // is the latter then we auto move to the next review in the queue.
      let searchParams = new URLSearchParams(urlParams.search)
      let viewParam = searchParams.get('view')
      if (review.status === 'Closed' && !viewParam) {
        autoNavigateToReview(ndx)
      }
    }
  }

  const updateSidebar = review => {
    if (!review.review) return

    switch (review.saveAction) {
      case SaveActionTypes.ADD_TO_PROPOSED:
        if (ActionIDs.PROPOSED === activeFolderId) addToQueue(review.review)
        else removeFromQueue(review.review)
        break

      case SaveActionTypes.ADD_TO_ESCALATED:
        if (ActionIDs.ESCALATED === activeFolderId) addToQueue(review.review)
        else removeFromQueue(review.review)
        break

      case SaveActionTypes.ADD_TO_DISPUTED:
        if (ActionIDs.DISPUTED === activeFolderId) addToQueue(review.review)
        else removeFromQueue(review.review)
        break

      case SaveActionTypes.REMOVE_FROM_DISPUTED:
        if (ActionIDs.DISPUTED === activeFolderId) removeFromQueue(review.review)
        else addToQueue(review.review)
        break

      case SaveActionTypes.REMOVE_FROM_ESCALATED:
        if (ActionIDs.ESCALATED === activeFolderId) removeFromQueue(review.review)
        else addToQueue(review.review)
        break

      case SaveActionTypes.REMOVE_FROM_PROPOSED:
        if (ActionIDs.PROPOSED === activeFolderId) removeFromQueue(review.review)
        else addToQueue(review.review)
        break

      case SaveActionTypes.REMOVE_FROM_EXPIRED_PROPOSALS:
        if (ActionIDs.EXPIRED_PROPOSALS === activeFolderId) removeFromQueue(review.review)
        else addToQueue(review.review)
        break

      case SaveActionTypes.REMOVE_FROM_QUEUE:
        removeFromQueue(review.review)
        break

      default:
        break
    }
  }

  const autoNavigateToReview = ndx => {
    let review = sidebarReviews.length > ndx ? sidebarReviews[ndx] : null
    if (review) {
      let queryUrl = generatePath('/queue/:id', { id: review.reviewId })
      history.push(queryUrl)
    }
  }

  const scrollToReview = review => {
    if (!review.review) return

    let item = sidebarDiv.current.querySelector(`div[data-review-id='${review.review.reviewId}']`)
    if (item) {
      sidebarDiv.current.scrollTo({
        top: item.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const handlePageChangeClick = (e, page) => pagedItemSelected(page)

  useEffect(() => {
    if (updateWorkspace) {
      updateSidebar(review)
    }
  }, [updateWorkspace])

  useEffect(() => {
    if (review.review) {
      let ndx = sidebarReviews.findIndex(r => r.reviewId === review.review.reviewId)
      if (ndx !== -1) {
        sidebarReviews[ndx].status = review.review.status
      }
    }
  }, [review.review])

  useEffect(() => {
    if (fetchQuery) {
      fetchReviews(page, pageSize, fetchQuery)
    }
  }, [page, pageSize, fetchQuery])

  useEffect(() => {
    scrollToReview(review)
  })

  useEffect(() => {
    if (clearQueue) {
      setSidebarReviews([])
      setNumberOfPages(0)
    }
  }, [clearQueue])

  useEffect(() => {
    const onScroll = () => {
      var winScroll = sidebarDiv.current.scrollTop || document.documentElement.scrollTop
      var height = sidebarDiv.current.scrollHeight - sidebarDiv.current.clientHeight
      var scrolled = (winScroll / height) * 100
      scrollbarDiv.current.style.width = `${scrolled}%`
    }

    sidebarDiv.current.addEventListener('scroll', onScroll)
    return () => sidebarDiv.current.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      {fetchStarted && <PageProgressBar />}
      <div className='flex-scroll-container flex-column' hidden={showFilter}>
        {fetchStarted && <ProgressLoader />}
        <div
          ref={sidebarDiv}
          className='flex-grow-1 das-scroll-style-no-scrollbar'
          style={{
            backgroundColor: sidebarReviews.length > 0 ? theme.palette.background.dark : 'transparent',
          }}>
          {sidebarReviews.map(r => (
            <QueueListItem key={r.reviewId} review={r} history={history} />
          ))}
        </div>
        <ScrollProgressBar show={sidebarReviews.length > 0}>
          <div ref={scrollbarDiv} />
        </ScrollProgressBar>
        {numberOfPages > 1 && (
          <PagerView className='pt-2 pb-2'>
            <Pagination
              className={classes.pager}
              color='primary'
              page={page}
              count={numberOfPages}
              onChange={handlePageChangeClick}
            />
          </PagerView>
        )}
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review,
    updateWorkspace: state.review.updateWorkspace,
    page: state.reviews.page,
    pageSize: state.reviews.pageSize,
    fetchQuery: state.reviews.fetchQuery,
    showFilter: state.reviews.showFilter,
    clearQueue: state.reviews.clear,
    activeFolderId: state.reviews.activeFolderId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pagedItemSelected: page => {
      dispatch(pagedItemSelectionAction(page))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueSidebar)
